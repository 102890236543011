'use client';

import styled from 'styled-components';

import BaseButton from '@/src/components/BaseComponents/BaseButton';
import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import {font_small} from '@/theme/fonts';

import {ShoppingCartTooltipInterface} from './ShoppingCartHeader';

export const Wrapper = styled.div`
  position: relative;
  margin-left: auto;
`;

export const ShoppingCartButton = styled(BaseButton)<{$purchaseorderscount?: number}>`
  padding: 0;
  min-width: unset;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
  margin-left: auto;

  &.MuiButton-root {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({
    $purchaseorderscount,
    theme: {
      colors: {blue100, grey030},
    },
  }) =>
    !!$purchaseorderscount && $purchaseorderscount > 0
      ? `
        background-color: ${blue100} !important;
        
        &:hover {
          background-color: rgb(117, 122, 255) !important;
        }
      `
      : $purchaseorderscount === 0 &&
        `
        background-color: ${grey030} !important;
        
        &:hover {
          background-color: rgb(222, 222, 252) !important;
        }
    `};

  @media (max-width: 425px) {
    width: 36px;
    height: 36px;

    & img {
      width: 12px;
    }
  }
`;

export const ShoppingCartCount = styled.div`
  ${font_small};
  font-size: 11px;
  font-weight: 600;
  position: absolute;
  border: 2px solid ${({theme}) => theme.colors.grey000};
  top: -10px;
  left: 24px;
  border-radius: 50%;
  color: ${({theme}) => theme.colors.grey000};
  background-color: rgb(224, 79, 79);
  width: 25px;
  height: 25px;
  line-height: 20px;
  padding: 1px;
  box-sizing: border-box;

  @media (max-width: 425px) {
    top: -8px;
    left: 18px;
    line-height: 16px;
    width: 20px;
    height: 20px;
  }
`;

export const TooltipElement = styled.div<ShoppingCartTooltipInterface>`
  display: ${({dpl}) => dpl};
  width: 212px;
  position: absolute;
  right: -40px;
  bottom: -59px;
  z-index: 11;
  font-size: 13px;
  border-radius: 5px;
  white-space: normal;
  padding: 5px 10px;
  text-align: start;
  background: ${({theme}) => theme.colors.grey100};
  color: ${({theme}) => theme.colors.grey000};
  box-sizing: border-box;

  &::before {
    content: ' ';
    border-width: 11px;
    position: absolute;
    border-style: solid;
    border-color: transparent;
    top: -10px;
    left: 152px;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: ${({theme}) => theme.colors.grey100};
  }
`;

export const TooltipContent = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  cursor: default;
`;

export const Icon = styled(BaseIcon)<{purchaseorderscount: undefined | number; embedded: number}>`
  fill: ${({
    purchaseorderscount,
    theme: {
      colors: {blue100, grey000},
    },
  }) => (purchaseorderscount ? grey000 : blue100)} !important;
  width: 22px;
  height: 22px;
  display: block !important;
  position: absolute;
  top: ${({embedded}) => (embedded ? 8 : 7)}px;
  left: 7px;
`;

import {FC, useCallback, useMemo} from 'react';

import {usePathname} from 'next/navigation';

import {SubMenuItem} from '@/src/components/GeneralComponents/Sidebar/SidebarUtils';
import useMobile from '@/src/hooks/useMobile';

import * as Styled from './styled';

interface SidebarSubMenuProps {
  item: SubMenuItem;
  onOpenSideBar: (key?: string) => void;
}

const SidebarSubMenuItem: FC<SidebarSubMenuProps> = ({item: {key, name, to, icon, href, target}, onOpenSideBar}) => {
  const pathname = usePathname();
  const hash = typeof window !== undefined ? window.location.hash : '';
  const {isTablet} = useMobile();

  const getDataAttr = useMemo(() => (str: string) => (str.includes('#') ? str.substring(str.indexOf('#')) : str), []);

  const handleClickMenu = useCallback(() => {
    isTablet && onOpenSideBar();
  }, [isTablet, onOpenSideBar]);

  if (key === 'community') {
    return null;
  } else {
    return (
      <Styled.Wrapper
        key={key}
        className={to === `${pathname?.replace(/\/\s*$/, '')}${hash}` ? 'active-item' : ''}
        onClick={handleClickMenu}
      >
        <Styled.SubIcon icon={icon} boxW={20} boxH={20} />
        {to && (
          <Styled.Link href={to} passHref key={key} data-qa-auto={getDataAttr(to)}>
            {name}
          </Styled.Link>
        )}
        {!to && href && (
          <Styled.ExternalLink key={key} href={href} target={target ?? ''}>
            {name}
          </Styled.ExternalLink>
        )}
      </Styled.Wrapper>
    );
  }
};

export default SidebarSubMenuItem;

import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {usePathname} from 'next/navigation';

import Toggle from '@/assets/icons_refactor/Common/small-arrow.svg';
import ChatActiveMessages from '@/src/components/GeneralComponents/Sidebar/components/ChatActiveMessages';
import {MainMenuItem, SubMenuItem} from '@/src/components/GeneralComponents/Sidebar/SidebarUtils';
import useMobile from '@/src/hooks/useMobile';
import useTracking from '@/src/hooks/useTracking';
import {useGetUserDataQuery} from '@/src/lib/store/stores/api';
import {isServer} from '@/src/utils/environment';
import {GTMEvents} from '@/src/utils/globalEvents';

import SidebarSubMenuItem from './components/SidebarSubMenuItem';
import * as Styled from './styled';

interface SidebarProps {
  isStartOpen: boolean;
  item: MainMenuItem;
  key: string;
  availableSubMenus: SubMenuItem[];
  onOpenSideBar: (key?: string) => void;
}

const {click_chat} = GTMEvents;

const SidebarItem = ({item, isStartOpen, availableSubMenus, onOpenSideBar}: SidebarProps) => {
  const {isTablet} = useMobile();
  const {data: userData} = useGetUserDataQuery();

  const {gtmTrack} = useTracking();
  const [hash, setHash] = useState<string>('');

  const pathname = usePathname();

  const [submenu, setSubmenu] = useState(false);
  useEffect(() => {
    isStartOpen && setSubmenu(true);
  }, [isStartOpen]);

  const toggleShowMenu = useCallback(() => setSubmenu((prev) => !prev), []);

  const isSubMenuSelectedItem = useMemo(
    () =>
      item?.submenu?.find(
        ({to, href}) => (!!to && (pathname + hash).includes(to)) || (!!href && (pathname + hash).includes(href))
      )
        ? 1
        : 0,
    [pathname, hash, item?.submenu]
  );

  const getMenuItemClasses = useMemo(
    () => `${submenu ? 'isOpen' : ''} ${isStartOpen ? 'active' : ''} `,
    [submenu, isStartOpen]
  );

  const {key, icon, to, name, href, iconComponent} = item;

  const isSelected = useMemo(() => (to && pathname?.includes(to) ? 1 : 0), [to, pathname]);

  const renderExternalLink = useMemo(
    () =>
      href ? (
        <Styled.ExternalLink key={key} href={href} target="_blank">
          {name}
        </Styled.ExternalLink>
      ) : (
        <Styled.MenuItemTitle>{name}</Styled.MenuItemTitle>
      ),
    [href, key, name]
  );

  const handleClickMenu = useCallback(() => {
    if (item.submenu) {
      toggleShowMenu();
    } else {
      isTablet && onOpenSideBar();
    }

    if (item.key === 'blendTalk') {
      userData?.type && gtmTrack({event: click_chat, user_role: userData?.type});
    }
  }, [isTablet, item, onOpenSideBar, toggleShowMenu, userData?.type]);

  useEffect(() => {
    if (isServer()) {
      return;
    }
    const handleHashChange = () => setHash(window.location.hash);
    handleHashChange();
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <Styled.Wrapper data-qa-auto={`${key}-sidebar-section`} $isOpen={submenu}>
      <>
        <Styled.MenuItem
          className={getMenuItemClasses}
          $selected={isSubMenuSelectedItem}
          $open={isStartOpen ? 1 : 0}
          onClick={handleClickMenu}
        >
          {!!icon && <Styled.Icon icon={icon} $selected={isSelected} boxW={20} boxH={20} />}
          {iconComponent && <Styled.IconComponent $selected={isSelected}>{iconComponent}</Styled.IconComponent>}
          {to ? (
            <Styled.InternalLink key={key} href={to} $selected={isSelected}>
              {name}
            </Styled.InternalLink>
          ) : (
            renderExternalLink
          )}
          {item.key === 'blendTalk' && <ChatActiveMessages sideBar show />}
          {item.submenu && <Styled.Arrow icon={Toggle} />}
        </Styled.MenuItem>
        {submenu &&
          availableSubMenus.map((submenuItem) => (
            <SidebarSubMenuItem key={submenuItem.key} item={submenuItem} onOpenSideBar={onOpenSideBar} />
          ))}
      </>
    </Styled.Wrapper>
  );
};

export default SidebarItem;

import {ChangeEvent, FC, useCallback, useMemo, useState} from 'react';

import Toggle from '@/assets/icons_refactor/Common/sidebar-toogle.svg';
import NoAvatar from '@/assets/icons_refactor/Header/avatar.svg';
import {switchBeneficiaryAccount} from '@/src/api/profile';
import BaseSingleSelect from '@/src/components/BaseComponents/BaseSingleSelect';
import useMobile from '@/src/hooks/useMobile';
import {useSession} from '@/src/hooks/useSession';
import useUserData from '@/src/hooks/useUserData';
import {useGetUserDataQuery} from '@/src/lib/store/stores/api';
import {isBrowser} from '@/src/utils/environment';

import * as Styled from './styled';

interface Props {
  isOpenSidebar: boolean;
  setSidebarToggle: (sidebarToggle: boolean) => void;
  hideLogoutBtn?: boolean;
  embedded?: boolean;
  isHidden: boolean;
}

const UserBar: FC<Props> = ({isOpenSidebar, setSidebarToggle, hideLogoutBtn, embedded, isHidden}) => {
  const {data: userData} = useGetUserDataQuery();

  const isLoggedIn: boolean = useMemo(() => !!userData?.id, [userData?.id]);

  const {
    billingAddress: {Company = ''} = {},
    thumb: {src = null} = {},
    name = 'Stranger',
    isAdmin = false,
    id: userID = '',
    statistics: {unreadMessages = 0} = {},
  } = userData ?? {};

  const {CSRFToken = ''} = useSession() || {};
  const {handleLogout} = useUserData();

  const {isMobile, isTablet} = useMobile();

  const [showBeneficiary, setShowBeneficiary] = useState<boolean>(false);

  const handleLogoutClick = useCallback(() => handleLogout(CSRFToken), [CSRFToken, handleLogout]);

  const handleToggleClick = useCallback(() => {
    setSidebarToggle?.(!isOpenSidebar);
  }, [isOpenSidebar]);

  const handleAvatarClick = () => {
    !isTablet && handleToggleClick();
  };

  const beneficiary = userData && Object.entries(userData.beneficiary);
  const beneficiaryAccounts = useMemo(
    () =>
      beneficiary?.map((item) => ({
        value: item[0],
        label: `Switch to: ${item[1]}`,
      })),
    [beneficiary]
  );

  const handleChange = useCallback(
    ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
      switchBeneficiaryAccount(CSRFToken, value).then(() => (isBrowser() ? window.location.reload() : null));
    },
    [CSRFToken]
  );

  const handleOpenDropdown = useCallback(() => setShowBeneficiary(true), []);
  const handleCloseDropdown = useCallback(() => setShowBeneficiary(false), []);

  const messageText = useMemo(() => `${unreadMessages} Message${unreadMessages === 1 ? '' : 's'}`, [unreadMessages]);

  return (
    <Styled.Wrapper
      className="userbar"
      $embedded={+!!embedded}
      $ishidden={+(isHidden || !isOpenSidebar)}
      $istablet={+isTablet}
      $isopensidebar={+!isOpenSidebar}
    >
      <Styled.Menu>
        <Styled.Additional>
          <Styled.AvatarWrapper onClick={handleAvatarClick}>
            {src ? (
              <Styled.Avatar
                src={src}
                alt="avatar"
                $embedded={embedded ? 1 : 0}
                width={embedded ? 36 : 52}
                height={embedded ? 36 : 52}
              />
            ) : (
              <Styled.Guest icon={NoAvatar} boxW={37} boxH={37} $embedded={+!!embedded} />
            )}
          </Styled.AvatarWrapper>

          {isOpenSidebar && (
            <div>
              <Styled.Name
                data-qa-auto="hello-user"
                onPointerEnter={handleOpenDropdown}
                onClick={handleCloseDropdown}
                $embedded={embedded ? 1 : 0}
                $islogedin={+isLoggedIn}
              >
                Hello, {name?.slice(0, 10)}
                {beneficiaryAccounts?.length ? (
                  <BaseSingleSelect
                    isOpen={showBeneficiary}
                    options={beneficiaryAccounts}
                    onChange={handleChange}
                    dropdownClassname="switch-to-beneficiary"
                    value=""
                    variant="outlined"
                  />
                ) : null}
              </Styled.Name>
              {isLoggedIn && (
                <>
                  {!embedded && (
                    <Styled.CompanyName>
                      {Company?.slice(0, 10)} <span data-qa-auto="user-id">#{userID}</span>
                    </Styled.CompanyName>
                  )}
                  <Styled.ButtonsArea>
                    {isAdmin && (
                      <Styled.Button href="/admin" $embedded={+!!embedded}>
                        Admin
                      </Styled.Button>
                    )}
                    {embedded && isAdmin && <Styled.Separator>|</Styled.Separator>}
                    {(isMobile || embedded) && (
                      <Styled.MsgBtn $embedded={+!!embedded} href="/mbox">
                        {messageText}
                      </Styled.MsgBtn>
                    )}
                    {!hideLogoutBtn && (
                      <Styled.Button $embedded={0} onClick={handleLogoutClick}>
                        Logout
                      </Styled.Button>
                    )}
                  </Styled.ButtonsArea>
                </>
              )}
            </div>
          )}
        </Styled.Additional>
      </Styled.Menu>
      {isOpenSidebar && <Styled.ToggleIcon icon={Toggle} onClick={handleToggleClick} boxW={20} boxH={20} />}
    </Styled.Wrapper>
  );
};

export default UserBar;

'use client';

import Link from 'next/link';
import styled, {css} from 'styled-components';

import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import BaseLink from '@/src/components/BaseComponents/BaseLink';
import {getLinkColor} from '@/src/components/GeneralComponents/Sidebar/SidebarUtils';
import {boldWeight, font_large} from '@/theme/fonts';

export const Wrapper = styled.div<{$isOpen: boolean}>`
  transition: color 0.2s;
  border-bottom: 1px solid ${({theme, $isOpen}) => ($isOpen ? theme.colors.grey020 : 'transparent')};
`;

export const LinkStyle = css`
  ${boldWeight};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

export const ExternalLink = styled(BaseLink)`
  ${LinkStyle};
  ${font_large};
  ${boldWeight};
  color: ${({theme}) => theme.colors.grey090};
`;

export const InternalLink = styled(Link)<{$selected?: number}>`
  ${LinkStyle};
  color: ${({theme, $selected}) => theme.colors[getLinkColor($selected)]};

  &:hover {
    color: ${({theme, $selected}) => theme.colors[getLinkColor($selected)]};
  }
`;

export const Icon = styled(BaseIcon)<{$selected?: number}>`
  width: 20px !important;
  height: 20px !important;
  fill: ${({
    theme: {
      colors: {grey090, blue100},
    },
    $selected,
  }) => ($selected ? blue100 : grey090)};

  &.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    fill: ${({
      theme: {
        colors: {grey090, blue100},
      },
      $selected,
    }) => ($selected ? blue100 : grey090)};
  }
`;

export const SubIcon = styled(Icon)`
  margin-right: 10px;
  fill: ${({theme}) => theme.colors.grey080};
`;

export const Arrow = styled(SubIcon)`
  cursor: pointer;
`;

export const MenuItemTitle = styled.div`
  cursor: pointer;
  user-select: none;
`;

export const MenuItem = styled.div<{$selected?: number; $open?: number}>`
  ${font_large};
  ${boldWeight};
  position: relative;
  display: grid;
  align-items: center;
  padding: 7px 20px;
  grid-template-columns: 20px auto 15px;
  grid-template-rows: 40px;
  grid-column-gap: 10px;
  text-decoration: none;
  color: ${({
    theme: {
      colors: {blue100, grey090},
    },
    $selected,
  }) => ($selected ? blue100 : grey090)};
  background-color: ${({
    theme: {
      colors: {blue020},
    },
    $selected,
  }) => ($selected ? blue020 : 'inherit')};

  ${Icon} {
    fill: ${({
      theme: {
        colors: {blue100},
      },
      $selected,
    }) => ($selected ? blue100 : '')};
  }

  ${Arrow} {
    transform: ${({$open}) => `rotate(${$open ? 'rotate(-180deg)' : 'rotate(0deg)'})`};
  }
`;

export const IconComponent = styled.div<{$selected: number}>`
  svg {
    fill: ${({
      theme: {
        colors: {grey090, blue100},
      },
      $selected,
    }) => ($selected ? blue100 : grey090)};
  }

  div {
    div {
      top: 15px;
      left: 10px;
    }
  }
`;

import {FC, useState} from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import dynamic from 'next/dynamic';

import useUserData from '@/src/hooks/useUserData';
import {ModalMode} from '@/src/interfaces/types/auth.types';
import {useGetUserDataQuery} from '@/src/lib/store/stores/api';
import ConstTheme from '@/theme';

import AuthSocial from '../AuthSocial';
import Title from '../Title';
import * as Styled from './styled';

const Form = dynamic(() => import('./components/Form'), {ssr: false});

const {extraUserData} = ModalMode;

interface Props {
  mode: ModalMode;
  handleCloseModal: VoidFunction;
  needSocialButtons?: boolean;
}

const ExtraUserDataModal: FC<Props> = ({mode, handleCloseModal, needSocialButtons = false}) => {
  const isMobile = useMediaQuery(ConstTheme.breakpoints.maxSm);
  const {isFetchingAuth} = useUserData();

  const {data: userData} = useGetUserDataQuery();

  const [emailInput, setCurrentEmail] = useState<string>('');

  return (
    <Styled.DialogItem
      onClose={handleCloseModal}
      aria-labelledby="auth-dialog-title"
      fullWidth={isMobile}
      open={mode === extraUserData}
      disableEscapeKeyDown={isFetchingAuth}
    >
      <Title id="auth-dialog-title" onClose={handleCloseModal} disableClose={isFetchingAuth}>
        {`Hi ${userData?.name || ''}`}
      </Title>
      <Styled.ModalContent>
        {needSocialButtons && <AuthSocial />}
        <Form emailUpdatedHandler={setCurrentEmail} emailParam={emailInput} />
      </Styled.ModalContent>
    </Styled.DialogItem>
  );
};

export default ExtraUserDataModal;

'use client';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styled from 'styled-components';

import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import {font_large} from '@/theme/fonts';

export const Wrapper = styled(Select)`
  background-color: ${({theme}) => theme.colors.grey000};

  & .select-input {
    border: none;
  }

  & .select-focused,
  & .select-root {
    background-color: ${({theme}) => theme.colors.grey000};
  }

  & .select-root {
    & svg {
      margin-right: 10px;
    }
  }

  div.MuiSelect-select {
    padding-right: 50px;
  }
`;

export const Item = styled(MenuItem)<{$icon?: number}>`
  display: flex;
  align-items: center;
  ${font_large};

  & svg {
    margin-right: ${({$icon}) => ($icon ? '10px' : '0')};
  }
`;

export const CaretIcon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.grey080};
  margin-left: 10px;
  top: 10px;
  width: 28px;
  height: 28px;
  border: none;

  svg {
    max-height: unset;
    max-width: unset;
    width: 28px;
    height: 28px;
  }
`;

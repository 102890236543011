'use client';

import {useEffect} from 'react';

import {datadogLogs} from '@datadog/browser-logs';

export default function DatadogBrowserLogger({token, service, env, version}) {
  useEffect(() => {
    if (token) {
      datadogLogs.init({
        clientToken: token,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        service: service,
        env: env,
        version: version,
      });
    }
  }, [token]);

  return null;
}

import {useCallback, useEffect, useMemo, useState} from 'react';

import {useRouter} from 'next/navigation';

import ShoppingBagIcon from '@/assets/icons_refactor/Header/shopping-bag.svg';
import useTracking from '@/src/hooks/useTracking';
import {useGetUserDataQuery} from '@/src/lib/store/stores/api';
import {USER_ROLES} from '@/src/types/Wizard/types';
import {GTMEvents} from '@/src/utils/globalEvents';

import * as Styled from './styled';

const {translator} = USER_ROLES;

export interface ShoppingCartTooltipInterface {
  dpl: string;
}

interface PurchaseOrdersCountProps {
  purchaseOrdersCount: undefined | number;
  embedded?: boolean;
}

const ShoppingCartHeader = ({purchaseOrdersCount, embedded}: PurchaseOrdersCountProps) => {
  const {data: userData} = useGetUserDataQuery();
  const {gtmTrack} = useTracking();

  const router = useRouter();
  const [isTranslator, setTranslator] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(true);

  const shoppingCartPage = useCallback(() => {
    gtmTrack({
      event: GTMEvents.header_click,
      user_id: userData?.id,
    });
    router.push('/shopping-cart');
  }, [userData]);

  const handleTooltip = useCallback(() => {
    localStorage.setItem('shoppingCartTooltip', '1');
    setShowTooltip(false);
  }, []);

  useEffect(() => {
    const shoppingCartTooltip = localStorage.getItem('shoppingCartTooltip');
    if (shoppingCartTooltip) setShowTooltip(false);
  }, []);

  useEffect(() => {
    if (userData) setTranslator(userData?.type === translator);
  }, [userData]);

  const isEmptyCount = useMemo(() => (purchaseOrdersCount === 0 ? 'block' : 'none'), [purchaseOrdersCount]);
  const isCountPositive = useMemo(
    () => (!!purchaseOrdersCount && purchaseOrdersCount > 0 ? 'block' : isEmptyCount),
    [isEmptyCount, purchaseOrdersCount]
  );

  return (
    <>
      {!isTranslator && (
        <Styled.Wrapper>
          <Styled.ShoppingCartButton
            $purchaseorderscount={purchaseOrdersCount}
            onClick={shoppingCartPage}
            data-qa-auto="shopping-cart-basket-btn"
            id="shopping-cart"
            className={isEmptyCount ? 'emptyCart' : ''}
          >
            <Styled.Icon
              icon={ShoppingBagIcon}
              purchaseorderscount={purchaseOrdersCount}
              boxW={24}
              boxH={24}
              embedded={embedded ? 1 : 0}
            />
            {!!purchaseOrdersCount && purchaseOrdersCount > 0 && (
              <Styled.ShoppingCartCount>{purchaseOrdersCount}</Styled.ShoppingCartCount>
            )}
          </Styled.ShoppingCartButton>

          {purchaseOrdersCount === 0 && (
            <Styled.TooltipElement onClick={handleTooltip} dpl={showTooltip ? isCountPositive : 'none'}>
              <Styled.TooltipContent>
                <span>It looks like you don’t have any project drafts at this moment.</span>
              </Styled.TooltipContent>
            </Styled.TooltipElement>
          )}
        </Styled.Wrapper>
      )}
    </>
  );
};

export default ShoppingCartHeader;

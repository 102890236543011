import React, {CSSProperties, forwardRef} from 'react';

import {Link, LinkProps} from 'react-router-dom';

import * as Styled from './styled';

interface CustomLinkProps extends LinkProps {
  style?: CSSProperties;
}

const CustomRouterLink = forwardRef<HTMLDivElement, CustomLinkProps>((props, ref) => (
  <Styled.Wrapper ref={ref}>
    <Link {...props} />
  </Styled.Wrapper>
));

CustomRouterLink.displayName = 'CustomRouterLink';

export default CustomRouterLink;

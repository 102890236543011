'use client';

import {Suspense} from 'react';

import '@/theme/base.css';

import CssBaseline from '@mui/material/CssBaseline';
import {StyleSheetManager, ThemeProvider} from 'styled-components';

import Providers from '@/src/components/Providers';
import {PropsWithPreloadedState} from '@/src/interfaces/types/layout';
import StyledComponentsRegistry from '@/src/lib/registry';
import {OverrideStyles} from '@/theme/overrideStyles';
import theme from '@/theme/theme';

import AppLayoutInner from './AppLayoutInner';

const AppLayout = ({children, preloadedState}: PropsWithPreloadedState) => (
  <Providers preloadedState={preloadedState}>
    <StyleSheetManager shouldForwardProp={() => true}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <OverrideStyles />
        <StyledComponentsRegistry>
          <Suspense fallback={<></>}>
            <AppLayoutInner>{children}</AppLayoutInner>
          </Suspense>
        </StyledComponentsRegistry>
      </ThemeProvider>
    </StyleSheetManager>
  </Providers>
);

export default AppLayout;

'use client';

import styled, {css} from 'styled-components';

import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import {font_xsmall} from '@/theme/fonts';

export const overflowStyles = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Wrapper = styled.div<{selected: number}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${({
    selected,
    theme: {
      colors: {blue020, grey000},
    },
  }) => (selected ? blue020 : grey000)};
`;

export const Counter = styled.div`
  ${font_xsmall};
  background-color: ${({theme}) => theme.colors.blue100};
  color: ${({theme}) => theme.colors.grey000};
  position: absolute;
  top: 3px;
  right: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${overflowStyles};
`;

export const Icon = styled(BaseIcon)<{selected: number}>`
  fill: ${({
    selected,
    theme: {
      colors: {grey090, blue100},
    },
  }) => (selected ? blue100 : grey090)};
`;

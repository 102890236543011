import {FC} from 'react';

import {ModalMode} from '@/src/interfaces/types/auth.types';

import * as Styled from './styled';

const {login, signup} = ModalMode;

interface Props {
  handleOpenModal: (type: ModalMode) => void;
  isMobile: boolean;
}

const AuthButtons: FC<Props> = ({handleOpenModal, isMobile}) => (
  <Styled.Wrapper>
    <Styled.LoginButton onClick={() => handleOpenModal(login)}>Sign in</Styled.LoginButton>

    {!isMobile ? (
      <Styled.RegisterButton onClick={() => handleOpenModal(signup)}>
        <span style={{color: 'white'}}>Register</span>
      </Styled.RegisterButton>
    ) : null}
  </Styled.Wrapper>
);

export default AuthButtons;

'use client';

import styled from 'styled-components';

import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import {font_xsmall} from '@/theme/fonts';

export const Wrapper = styled.div<{sidebar: number}>`
  position: ${({sidebar}) => (sidebar ? 'absolute' : 'relative')};
  ${({sidebar}) =>
    sidebar &&
    `
      top: 10px;
      left: 42px;
  `}
`;

export const ChatIcon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.grey000};
  margin-top: 5px;

  svg {
    display: block !important;
    fill: ${({theme}) => theme.colors.grey000};
  }
`;

export const MessageCount = styled.div`
  ${font_xsmall};
  background-color: ${({theme}) => theme.colors.blue100};
  color: ${({theme}) => theme.colors.grey000};
  position: absolute;
  top: 0;
  right: -6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

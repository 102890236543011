'use client';

import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 54px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconComponent = styled.div`
  cursor: pointer;
  fill: ${({theme}) => theme.colors.grey090};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    padding: 10px;
    box-sizing: content-box;
  }

  &.active {
    svg {
      background-color: ${({theme}) => theme.colors.blue020};
      fill: ${({theme}) => theme.colors.blue100};
      border-radius: 10px;
    }
  }
`;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {AdminSubmarineState} from '@/src/interfaces/types/adminSubmarine.types';

const initialState: AdminSubmarineState = {
  isAdminActionsOpen: false,
  project: {id: '', isExist: false},
  user: {
    userIdFromInput: '',
    userPreview: {
      ID: '',
      UserName: '',
      eMail: '',
    },
    userError: '',
    isUserLoading: false,
  },
};

const adminSubmarineSlice = createSlice({
  name: 'adminSubmarine',
  initialState,
  reducers: {
    setOpenAdminActions(state, {payload}: PayloadAction<boolean>) {
      state.isAdminActionsOpen = payload;
    },
    setProjectInfo(state, {payload}: PayloadAction<Partial<AdminSubmarineState['project']>>) {
      state.project = {...state.project, ...payload};
    },
    setAdminUser(state, {payload}: PayloadAction<Partial<AdminSubmarineState['user']>>) {
      state.user = {...state.user, ...payload};
    },
  },
});

export const {setOpenAdminActions, setProjectInfo, setAdminUser} = adminSubmarineSlice.actions;

export default adminSubmarineSlice.reducer;

'use client';

import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

import {largeFontSize} from '@/theme/fonts';

export const GeneralWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({theme}) => theme.colors.grey100};
  height: 57px;
  align-items: center;
  padding: 0 44px;
`;

export const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  background: ${({theme}) => theme.colors.grey100};
  height: 57px;
  align-items: center;
  padding: 0 44px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 3;

  & .nav-item {
    & a {
      ${largeFontSize};
      color: ${({theme}) => theme.colors.grey000};
      font-weight: 400;

      &:hover {
        color: rgb(138, 136, 255);
      }
    }

    &:hover {
      background-color: transparent;
    }
  }

  @media ${({theme}) => theme.breakpoints.maxMd} {
    padding: 0 25px;

    .nav {
      display: none;
    }
  }
`;

export const UserBlock = styled.div<{$istranslator: number}>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: ${({$istranslator}) => ($istranslator ? 'auto' : '80px')};
`;

export const MenuBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuBtn = styled(IconButton)`
  color: ${({theme}) => theme.colors.grey000};
  width: 50px;
`;

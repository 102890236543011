import React, {FC, useMemo} from 'react';

import {Chat} from '@mui/icons-material';

import {useGetBlendTalkNotificationsQuery, useGetUserDataQuery} from '@/src/lib/store/stores/api';
import {USER_ROLES} from '@/src/types/Wizard/types';
import {transformToChatNotifications} from '@/src/utils/chatNotifications';

const {translator} = USER_ROLES;

import * as Styled from './styled';

interface Props {
  sideBar?: boolean;
  show?: boolean;
}

const ChatActiveMessages: FC<Props> = ({sideBar, show}) => {
  const {data: userData} = useGetUserDataQuery();

  const isTranslator = useMemo(() => userData?.type === translator, [userData?.type]);

  const {data: notifications = []} = useGetBlendTalkNotificationsQuery();

  const totalCount = Object.values(transformToChatNotifications(notifications)).reduce(
    (total, notificationsArray) => total + notificationsArray.length,
    0
  );

  return (
    <>
      {(isTranslator || show) && !!totalCount && (
        <Styled.Wrapper id="blend_talk_messages" sidebar={sideBar ? 1 : 0}>
          {!sideBar && (
            <a href="/blend-talk/chat/">
              <Styled.ChatIcon icon={Chat} />
            </a>
          )}
          <Styled.MessageCount>{totalCount >= 100 ? '99+' : totalCount}</Styled.MessageCount>
        </Styled.Wrapper>
      )}
    </>
  );
};

export default ChatActiveMessages;

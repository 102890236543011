import {FC, ReactElement, ReactNode, useMemo} from 'react';

import OutlinedInput from '@mui/material/OutlinedInput';
import {SelectChangeEvent, SelectProps} from '@mui/material/Select';

import ArrowDown from '@/assets/icons_refactor/Common/small-arrow.svg';

import * as Styled from './styled';

export interface OptionsProps {
  value: string | number;
  label: string | number;
  icon?: ReactNode;
  disabled?: boolean;
  endAdornment?: ReactNode;
}

interface Props extends Omit<SelectProps, 'value' | 'defaultValue'> {
  options: OptionsProps[];
  onChange?: (e: SelectChangeEvent) => void;
  value?: string | number | null;
  defaultValue?: string;
  dropdownIcon?: ReactNode;
  isOpen?: boolean;
  dropdownClassname?: string;
  selectAttribute?: string;
  handleClick?: VoidFunction;
  displayEmpty?: boolean;
}

const BaseSingleSelect: FC<Props> = ({
  defaultValue,
  options,
  value,
  onChange,
  isOpen,
  dropdownClassname,
  dropdownIcon,
  selectAttribute,
  handleClick,
  displayEmpty,
  ...other
}) => {
  const input = useMemo(
    () => <OutlinedInput classes={{notchedOutline: 'select-input', focused: 'select-focused'}} />,
    []
  );

  const selectClasses = useMemo(
    () => ({
      root: dropdownClassname ? dropdownClassname : undefined,
      select: 'select-root',
    }),
    [dropdownClassname]
  );

  return (
    <Styled.Wrapper
      {...(other as any)}
      open={isOpen}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue || undefined}
      input={input}
      classes={selectClasses}
      IconComponent={(props: ReactElement) =>
        dropdownIcon || <Styled.CaretIcon icon={ArrowDown} {...props} boxW={20} boxH={20} />
      }
      data-qa-auto={selectAttribute}
      onClick={handleClick}
      displayEmpty={displayEmpty}
    >
      {options.map(({value: optionValue, label, icon, disabled, endAdornment}: OptionsProps) => (
        <Styled.Item key={`${label}_${optionValue}`} value={optionValue} $icon={icon ? 1 : 0} disabled={disabled}>
          {icon && icon}
          {label}
          {endAdornment && endAdornment}
        </Styled.Item>
      ))}
    </Styled.Wrapper>
  );
};

export default BaseSingleSelect;

import {MouseEvent, useState} from 'react';

import Leave from '@/assets/icons_refactor/BlendTalk/leave.svg';
import Search from '@/assets/icons_refactor/Client/search.svg';
import Integration from '@/assets/icons_refactor/SideMenu/integration.svg';
import Software from '@/assets/icons_refactor/SideMenu/software.svg';
import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import {useSession} from '@/src/hooks/useSession';
import useUserData from '@/src/hooks/useUserData';
import {isBrowser} from '@/src/utils/environment';

import Consts from '../HeaderConsts';
import * as Styled from './styled';

const {
  LOG_OUT,
  PANEL,
  SEARCH,
  DATA_QA: {ADMIN_HEADER_SEARCH, ADMIN_HEADER_LOGOUT, ADMIN_HEADER_C_PANEL},
} = Consts;

interface MenuHeaderOptions {
  onSearch: VoidFunction;
  onCpanel: VoidFunction;
  onLogout: VoidFunction;
}

const menuHeaderOptions = ({onSearch, onCpanel, onLogout}: MenuHeaderOptions) => [
  {
    item: SEARCH,
    icon: (
      <span data-qa-auto={ADMIN_HEADER_SEARCH}>
        <BaseIcon icon={Search} />
      </span>
    ),
    action: onSearch,
  },
  {
    item: PANEL,
    icon: (
      <span data-qa-auto={ADMIN_HEADER_C_PANEL}>
        <BaseIcon icon={Integration} />
      </span>
    ),
    action: onCpanel,
  },
  {
    item: LOG_OUT,
    icon: (
      <span data-qa-auto={ADMIN_HEADER_LOGOUT}>
        <BaseIcon icon={Leave} />
      </span>
    ),
    action: onLogout,
  },
];

const AdminHeaderDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const session = useSession();
  const {handleLogout} = useUserData();

  const onSearch = () => (isBrowser() ? (window.location.href = '/admin') : null);
  const onCpanel = () => (isBrowser() ? (window.location.href = '/cpanel') : null);
  const onLogout = () => session?.CSRFToken && handleLogout(session?.CSRFToken);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = !!anchorEl;
  const id = open ? 'admin-header-popover' : undefined;

  return (
    <>
      <Styled.Wrapper onClick={handleClick}>
        <BaseIcon icon={Software} boxH={20} boxW={18} />
      </Styled.Wrapper>
      <Styled.StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableScrollLock
      >
        <Styled.MenuItemWrapper>
          {menuHeaderOptions({onSearch, onCpanel, onLogout}).map(({item, action, icon}) => (
            <Styled.Item key={item} onClick={action}>
              {icon}
              {item}
            </Styled.Item>
          ))}
        </Styled.MenuItemWrapper>
      </Styled.StyledPopover>
    </>
  );
};

export default AdminHeaderDropdown;

'use client';

import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import styled from 'styled-components';

import {largeFontSize} from '@/theme/fonts';

export const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: ${({theme}) => theme.colors.grey000};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease;
  margin-left: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.5s ease;
  }
`;

export const MenuItemWrapper = styled.div`
  padding: 8px;
  box-sizing: border-box;
`;

export const Item = styled(MenuItem)`
  ${largeFontSize};
  background-color: ${({theme}) => theme.colors.grey000};
  color: ${({theme}) => theme.colors.grey100};
  min-width: 180px;
  column-gap: 10px;

  &:hover {
    background-color: ${({theme}) => theme.colors.grey030};
  }
`;

'use client';

import styled from 'styled-components';

import BaseButton from '@/src/components/BaseComponents/BaseButton';
import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import BaseImage from '@/src/components/BaseComponents/BaseImage';
import {boldWeight, font_large, font_medium} from '@/theme/fonts';

export const Wrapper = styled.div<{$embedded: number; $ishidden: number; $istablet: number; $isopensidebar: number}>`
  height: ${({$embedded}) => ($embedded ? 57 : 104)}px;
  display: grid;
  grid-column-gap: 12px;
  grid-template-columns: auto ${({$isopensidebar}) => !$isopensidebar && '20px'};
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid ${({theme, $embedded}) => ($embedded ? 'none' : theme.colors.grey010)};
  position: fixed;
  top: 57px;
  width: fit-content;
  background-color: ${({theme}) => theme.colors.grey000};
  z-index: 10;
  max-width: 280px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: ${({$ishidden}) => ($ishidden ? 'none' : 'grid')};
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-left: -10px;
`;

export const Separator = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.grey000};
`;

export const Button = styled(BaseButton)<{$embedded: number}>`
  ${font_medium};
  padding: 0;
  color: ${({
    theme: {
      colors: {grey085, grey000},
    },
    $embedded,
  }) => ($embedded ? grey000 : grey085)};

  &:hover {
    color: ${({theme}) => theme.colors.blue100};
  }

  &.MuiButtonBase-root {
    text-transform: capitalize;
    height: 24px;

    .MuiButton-label {
      ${font_medium};
      text-transform: capitalize;
    }
  }
`;

export const Additional = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 25px;
  cursor: auto;
`;

export const Menu = styled.div`
  cursor: pointer;
  display: grid;
  align-items: center;
`;

export const Name = styled.div<{$embedded: number; $islogedin: number}>`
  ${({$embedded}) => ($embedded ? font_medium : font_large)};
  ${({$embedded}) => $embedded && boldWeight};
  color: ${({
    theme: {
      colors: {grey000, grey090},
    },
    $embedded,
  }) => ($embedded ? grey000 : grey090)};
  display: flex;
  align-items: center;
  width: 150px;
`;

export const CompanyName = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.grey080};

  & > span {
    color: ${({theme}) => theme.colors.grey090};
  }
`;

export const ToggleIcon = styled(BaseIcon)`
  cursor: pointer;
  fill: ${({theme}) => theme.colors.grey100};
  width: 20px;
  height: 20px;
  margin-top: 8px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: none;
  }
`;

export const Guest = styled(BaseIcon)<{$embedded: number}>`
  width: ${({$embedded}) => ($embedded ? 36 : 52)}px;
  height: ${({$embedded}) => ($embedded ? 36 : 52)}px;

  svg {
    width: ${({$embedded}) => ($embedded ? 36 : 52)}px;
    height: ${({$embedded}) => ($embedded ? 36 : 52)}px;
    flex-shrink: 0;
  }
`;

export const Avatar = styled(BaseImage)<{$embedded: number}>`
  width: ${({$embedded}) => ($embedded ? 36 : 52)}px;
  height: ${({$embedded}) => ($embedded ? 36 : 52)}px;
  border-radius: 26px;
`;

export const MsgBtn = styled(Button)`
  margin: 0 10px;
`;

export const AvatarWrapper = styled.div`
  @media ${({theme}) => theme.breakpoints.minMd} {
    cursor: pointer;
  }
`;

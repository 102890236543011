'use client';

import NextLink from 'next/link';
import styled from 'styled-components';

import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import BaseLink from '@/src/components/BaseComponents/BaseLink';
import {font_large} from '@/theme/fonts';

const LinkStyle = `
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

export const ExternalLink = styled(BaseLink)`
  ${LinkStyle};
  ${font_large};
  color: ${({theme}) => theme.colors.grey090};
`;

export const InternalLink = styled.a<{$selected?: boolean}>`
  ${LinkStyle};
  ${font_large};
  color: ${({theme}) => theme.colors.grey090};
  color: ${({theme, $selected}) => ($selected ? theme.colors.blue100 : theme.colors.grey090)};
`;

export const Icon = styled(BaseIcon)<{$selected?: number}>`
  width: 20px;
  height: 20px;
  fill: ${({
    theme: {
      colors: {grey090, blue100},
    },
    $selected,
  }) => ($selected ? blue100 : grey090)};
`;

export const Link = styled(NextLink)`
  ${LinkStyle};
  ${font_large};
  color: ${({theme}) => theme.colors.grey090};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 20px 7px 40px;
  text-decoration: none;
  color: ${({theme}) => theme.colors.grey090};

  ${InternalLink}, ${ExternalLink} {
    ${font_large};
    font-weight: normal;
    color: ${({theme}) => theme.colors.grey090};

    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }

  &:last-child {
    padding-bottom: 14px;
  }

  &.active,
  &.active-item {
    ${InternalLink},
    ${Link} {
      color: ${({theme}) => theme.colors.blue100};
    }

    ${Icon} {
      fill: ${({theme}) => theme.colors.blue100};
    }
  }
`;

export const SubIcon = styled(Icon)`
  margin-right: 10px;
  fill: ${({theme}) => theme.colors.grey080};
`;

'use client';

import styled from 'styled-components';

import BaseButton from '@/src/components/BaseComponents/BaseButton';
import {boldWeight} from '@/theme/fonts';

export const Wrapper = styled.div`
  display: flex;
  padding-right: 10px;
`;

export const LoginButton = styled(BaseButton)`
  ${boldWeight};
  background: #5857ff;
  color: ${({theme}) => theme.colors.grey000};
  border: none;
  margin-right: 5px;
  white-space: nowrap;
  margin-left: -25px;
`;

export const RegisterButton = styled(BaseButton)`
  background: #454d55;
  color: ${({theme}) => theme.colors.grey000};
  border: none;
`;

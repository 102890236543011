import {FC, useCallback, useMemo} from 'react';

import {usePathname} from 'next/navigation';

import BlendTalkIcon from '@/src/components/GeneralComponents/Sidebar/components/BlendTalkIcon';
import ToggleIcon from '@/src/components/GeneralComponents/Sidebar/components/SidebarItemSmall/components/ToggleIcon';
import {MainMenuItem} from '@/src/components/GeneralComponents/Sidebar/SidebarUtils';

import * as Styled from './styled';

interface Props {
  item: MainMenuItem;
  userType: string;
  getMenuItemClasses: (key: string, to?: string) => string;
  onOpenClosedSidebar: (value: string) => void;
}

const SidebarItemSmall: FC<Props> = ({
  item: {userTypes, iconComponent, icon, key, to},
  userType,
  getMenuItemClasses,
  onOpenClosedSidebar,
}) => {
  const pathname = usePathname();

  const handleOpenClosedSidebar = useCallback(() => onOpenClosedSidebar(key), [onOpenClosedSidebar, key]);

  const isSelectedBlendTalk = useMemo(() => pathname.includes('blend-talk/chat'), [pathname]);

  const renderIcon =
    key === 'blendTalk' ? (
      <BlendTalkIcon selected={isSelectedBlendTalk} navigation />
    ) : (
      <ToggleIcon className={getMenuItemClasses(key, to)} key={key} icon={icon} onClick={handleOpenClosedSidebar} />
    );

  if (!userTypes || userTypes.includes(userType)) {
    return (
      <Styled.Wrapper>
        {iconComponent ? (
          <Styled.IconComponent key={key} className={getMenuItemClasses(key, to)} onClick={handleOpenClosedSidebar}>
            {iconComponent}
          </Styled.IconComponent>
        ) : (
          renderIcon
        )}
      </Styled.Wrapper>
    );
  }
  return null;
};

export default SidebarItemSmall;

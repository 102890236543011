import {FC, PropsWithChildren} from 'react';

import PageLayout from '@/src/components/GeneralComponents/PageLayout';
import RouteChange from '@/src/components/GeneralComponents/RouteChange';

import WebSocketHandler from './Websocket';

const AppLayoutInner: FC<PropsWithChildren> = ({children}) => {
  return (
    <RouteChange>
      <PageLayout>{children}</PageLayout>
      <WebSocketHandler />
    </RouteChange>
  );
};

export default AppLayoutInner;

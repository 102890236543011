import React, {FC, useCallback, useEffect, useState} from 'react';

import {useRouter} from 'next/navigation';

import BlendTalk from '@/assets/icons_refactor/SideMenu/side-chat.svg';
import {useSession} from '@/src/hooks/useSession';
import {UnreadNotification} from '@/src/interfaces/types/blendTalk.types';
import {useGetBlendTalkNotificationsQuery} from '@/src/lib/store/stores/api';
import {transformToChatNotifications} from '@/src/utils/chatNotifications';

import * as Styled from './styled';

interface BlendTalkIconProps {
  selected?: boolean;
  navigation?: boolean;
}

const BlendTalkIcon: FC<BlendTalkIconProps> = ({selected, navigation}) => {
  const {loggedInUserId = 0} = useSession() || {};
  const [notif, setNotif] = useState<UnreadNotification[]>([]);

  const {data: notifications = [], isSuccess} = useGetBlendTalkNotificationsQuery(undefined, {
    skip: !loggedInUserId && !!notif.length,
  });

  useEffect(() => {
    isSuccess && setNotif(notifications);
  }, [notifications, isSuccess]);

  const newNotifications = transformToChatNotifications(notifications);

  const {push} = useRouter();

  const handleClick = useCallback(() => navigation && push('/blend-talk/chat/'), [navigation]);

  const totalCount = Object.values(newNotifications).reduce(
    (total, notificationsArray) => total + notificationsArray.length,
    0
  );

  return (
    <Styled.Wrapper selected={selected ? 1 : 0} onClick={handleClick}>
      <Styled.Icon icon={BlendTalk} boxH={20} boxW={20} selected={selected ? 1 : 0} />
      {!!totalCount && <Styled.Counter>{totalCount >= 100 ? '99+' : totalCount}</Styled.Counter>}
    </Styled.Wrapper>
  );
};

export default BlendTalkIcon;

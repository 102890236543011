import {FC, useEffect, useMemo, useState} from 'react';

import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {usePathname} from 'next/navigation';

import ExtraUserDataModal from '@/src/components/AuthModal/ExtraUserData/ExtraUserDataModal';
import LoginModal from '@/src/components/AuthModal/Login/LoginModal';
import SignUpModal from '@/src/components/AuthModal/Signup/SignUpModal';
import AuthButtons from '@/src/components/GeneralComponents/MainHeader/AuthButtons';
import ShoppingCartHeader from '@/src/components/ShoppingCart/ShoppingCartHeader';
import useMobile from '@/src/hooks/useMobile';
import useUserData from '@/src/hooks/useUserData';
import {ModalMode} from '@/src/interfaces/types/auth.types';
import {useGetUserDataQuery} from '@/src/lib/store/stores/api';
import {USER_ROLES} from '@/src/types/Wizard/types';

import * as Styled from './styled';
import AdminHeaderDropdown from '../components/AdminHeaderDropdown';
import Nav from '../components/Nav/Navs';
import Logo from '../HeaderLogo';

const {unset, login, signup, extraUserData} = ModalMode;

interface Props {
  purchaseOrdersCount: number;
  onOpenSideBar?: any;
  sidebarToggle?: boolean;
}

const {translator, admin} = USER_ROLES;

const HeaderView: FC<Props> = ({purchaseOrdersCount, onOpenSideBar, sidebarToggle}) => {
  const {isTablet, isMobile} = useMobile();
  const pathname = usePathname();
  const isInAuthPage = pathname?.includes('/auth');

  const [activeModalMode, setActiveModalMode] = useState<ModalMode>(unset);
  const {data: userData} = useGetUserDataQuery();

  const isLoggedIn: boolean = useMemo(() => !!userData?.id, [userData?.id]);

  const {createdUserId} = useUserData();

  const handleCloseModal = () => setActiveModalMode(unset);

  const handleOpenModal = (type: ModalMode) => setActiveModalMode(type);

  useEffect(() => {
    handleCloseModal();
  }, [isLoggedIn, createdUserId, userData]);

  const isPaymentPage = !!pathname?.includes('/payment');

  const isAdmin: boolean = useMemo(() => userData?.type === admin, [userData?.type]);

  return (
    <Styled.GeneralWrapper>
      <Styled.Wrapper>
        <Logo isDarkMode />
        <Nav />
        {isLoggedIn && (
          <Styled.MenuBlock>
            <Styled.UserBlock $istranslator={userData?.type === translator ? 1 : 0}>
              {!isPaymentPage && <ShoppingCartHeader purchaseOrdersCount={purchaseOrdersCount} />}
            </Styled.UserBlock>

            {isTablet && !isPaymentPage && (
              <Styled.MenuBtn onClick={onOpenSideBar} data-autotest-attr="hamburger">
                {!sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
              </Styled.MenuBtn>
            )}

            {isAdmin && <AdminHeaderDropdown />}
          </Styled.MenuBlock>
        )}

        {!isLoggedIn && !isInAuthPage ? <AuthButtons handleOpenModal={handleOpenModal} isMobile={isMobile} /> : <></>}
      </Styled.Wrapper>

      {activeModalMode === login && (
        <LoginModal isOpen handleCloseModal={handleCloseModal} handleOpenModal={handleOpenModal} />
      )}

      {activeModalMode === signup && (
        <SignUpModal isOpen handleCloseModal={handleCloseModal} handleOpenModal={handleOpenModal} registrationPage="" />
      )}

      {activeModalMode === extraUserData && (
        <ExtraUserDataModal mode={activeModalMode} handleCloseModal={handleCloseModal} />
      )}
    </Styled.GeneralWrapper>
  );
};

export default HeaderView;

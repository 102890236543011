import {FC} from 'react';

import * as Styled from './styled';

interface Props {
  className: string;
  icon?: FC;
  onClick: () => void;
}

const ToggleIcon: FC<Props> = ({onClick, className, icon}) => (
  <Styled.Wrapper className={className} icon={icon} onClick={onClick} boxH={20} boxW={24} />
);

export default ToggleIcon;
